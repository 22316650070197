<svelte:options tag="trello-card-badges" />

<script>
  import archiveBadge from '../icons/archive.svg';
  import attachmentBadge from '../icons/attachment.svg';
  import boardBadge from '../icons/board.svg';
  import checklistBadge from '../icons/checklist.svg';
  import clockBadge from '../icons/clock.svg';
  import commentBadge from '../icons/comment.svg';
  import descriptionBadge from '../icons/description.svg';
  import locationBadge from '../icons/location.svg';
  import selectionModeBadge from '../icons/selection-mode.svg';
  import subscribeBadge from '../icons/subscribe.svg';
  import templateCardBadge from '../icons/template-card.svg';
  import voteBadge from '../icons/vote.svg';

  export let badges = {};
  export let closed = false;
  export let template = false;
  // Why are these not camelcase you may ask?
  // Because web components are a bit silly and attributes have to be lowercase
  export let customfields;
  export let customfielditems;

  // due date math
  function getDueBadgeClass(dateString, dueComplete) {
    if (dueComplete) {
      return 'is-complete';
    }
    if (typeof dateString !== 'string') {
      return '';
    }
    const dueIn = new Date(dateString).getTime() - Date.now();
    if (dueIn <= 24 * 3600000 && dueIn >= 0) {
      return 'dueSoon';
    }
    if (dueIn < 0 && dueIn > -36 * 3600000) {
      return 'dueNow';
    }
    if (dueIn < 0) {
      return 'overdue';
    }
    return '';
  }

  $: dueBadgeClass = getDueBadgeClass((badges || {}).due, (badges || {}).dueComplete);

  $: checkItemsDueBadgeClass = getDueBadgeClass(
    (badges || {}).checkItemsEarliestDue,
    (badges || {}).checkItems === (badges || {}).checkItemsChecked
  );

  function toDateString(date) {
    const now = new Date();

    // The reasoning here being that if it's Jan 11, and you're formatting a
    // date like Dec 21, you're going to assume it means the previous December,
    // not next December. If we do mean the upcoming (in 11 months) December,
    // we'll show the year.
    //
    // It's tricky to see if this logic is correct, but what we want to say is
    // that we want to hide the year when it's a date from this year, unless
    // the month is ambiguous.
    //
    // The month is ambiguous when it's more than nine months away from today --
    // which is to say that there's a month in a different year that's only
    // three months away.

    // subtracting two dates returns the delta in milliseconds
    const daysApart = Math.abs(date - now) / 86400000;
    // on average 9 months is roughly 273.75 days
    const isAmbiguousMonth = daysApart > 273;
    const hideYear = now.getFullYear() === date.getFullYear() && !isAmbiguousMonth;

    if (hideYear) {
      return date.toLocaleDateString('default', { month: 'short', day: 'numeric' });
    }
    return date.toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' });
  }

  // custom field badges logic
  $: sumTrelloAttachments =
    badges && badges.attachmentsByType && badges.attachmentsByType.trello
      ? badges.attachmentsByType.trello.board + badges.attachmentsByType.trello.card
      : 0;

  $: visibleFields = (customfields || [])
    .filter((cf) => {
      if (!cf.display || !cf.display.cardFront) {
        return false;
      }
      // it may be tempting to use getFieldItem here but DON'T
      // this trips up Svelte's ability to detect changes here
      const item = (customfielditems || []).find((item) => item.idCustomField === cf.id);
      if (item == null) {
        return false;
      }
      // there is an item, check that we have something to display
      return !!getItemValue(cf);
    })
    .sort((a, b) => a.pos - b.pos);

  function getFieldItem(field) {
    return (customfielditems || []).find((item) => item.idCustomField === field.id);
  }

  function getOption(field, item) {
    return (field.options || []).find((option) => option.id === item.idValue);
  }

  function getItemValue(field) {
    const item = getFieldItem(field);
    if (!item) {
      return null;
    }
    if (field.type === 'list') {
      const option = getOption(field, item);
      if (!option || !option.value || !option.value.text) {
        return null;
      }
      return option.value.text;
    } else if (!item.value) {
      return null;
    }
    const val = item.value;
    switch (field.type) {
      case 'checkbox':
        return val.checked;
      case 'date':
        if (!val.date) {
          return null;
        }
        return toDateString(new Date(val.date));
      case 'number':
        if (!val.number) {
          return null;
        }
        return parseFloat(val.number).toLocaleString();
      case 'text':
        if (!val.text) {
          return null;
        }
        return val.text;
      default:
        return null;
    }
  }

  function dataImage(svg) {
    // for storybook these won't be inlined
    if (svg.endsWith('.svg')) {
      return svg;
    }
    return `data:image/svg+xml,${encodeURIComponent(svg)}`;
  }
</script>

<div class="badges">
  {#if template}
    <div class="badge is-template" title="This card is a template.">
      <img
        class="badge-icon"
        src={dataImage(templateCardBadge)}
        height="14"
        width="14"
        alt="Template Card badge"
      />
      <span class="badge-text">Template</span>
    </div>
  {/if}

  {#if closed}
    <div title="This card is archived." class="badge">
      <img
        class="badge-icon"
        src={dataImage(archiveBadge)}
        height="14"
        width="14"
        alt="Archived badge"
      />
      <span class="badge-text">Archived</span>
    </div>
  {/if}

  {#if badges}
    {#if badges.subscribed}
      <div title="You are watching this card." class="badge">
        <img
          class="badge-icon"
          src={dataImage(subscribeBadge)}
          height="14"
          width="14"
          alt="Watching badge"
        />
      </div>
    {/if}

    {#if badges.due}
      <div title="Due" class="badge {dueBadgeClass}">
        <img
          class="badge-icon"
          src={dataImage(clockBadge)}
          height="14"
          width="14"
          alt="Due date badge"
        />
        <span class="badge-text">
          {toDateString(new Date(badges.due))}
        </span>
      </div>
    {/if}

    {#if badges.description}
      <div title="This card has a description." class="badge">
        <img
          class="badge-icon"
          src={dataImage(descriptionBadge)}
          height="14"
          width="14"
          alt="Description badge"
        />
      </div>
    {/if}

    {#if badges.comments > 0}
      <div title="Comments" class="badge">
        <img
          class="badge-icon"
          src={dataImage(commentBadge)}
          height="14"
          width="14"
          alt="Comments badge"
        />
        <span class="badge-text">{badges.comments}</span>
      </div>
    {/if}

    {#if badges.attachments > sumTrelloAttachments}
      <div title="Attachments" class="badge">
        <img
          class="badge-icon"
          src={dataImage(attachmentBadge)}
          height="14"
          width="14"
          alt="Attachments badge"
        />
        <span class="badge-text">{badges.attachments - sumTrelloAttachments}</span>
      </div>
    {/if}

    {#if sumTrelloAttachments > 0}
      <div title="Attachments" class="badge">
        <img
          class="badge-icon"
          src={dataImage(boardBadge)}
          height="14"
          width="14"
          alt="Trello attachments badge"
        />
        <span class="badge-text">
          {sumTrelloAttachments}
        </span>
      </div>
    {/if}

    {#if badges.checkItems > 0}
      <div title="Checklist items" class="badge {checkItemsDueBadgeClass}">
        <img
          class="badge-icon"
          src={dataImage(checklistBadge)}
          height="14"
          width="14"
          alt="Checklist badge"
        />
        <span class="badge-text"
          >{badges.checkItemsChecked}/{badges.checkItems}
          {#if badges.checkItemsEarliestDue}
            • {toDateString(new Date(badges.checkItemsEarliestDue))}
          {/if}
        </span>
      </div>
    {/if}

    {#if badges.votes > 0}
      <div title="Votes" class={badges.viewingMemberVoted ? 'badge is-voted' : 'badge'}>
        <img
          class="badge-icon"
          src={dataImage(voteBadge)}
          height="14"
          width="14"
          alt="Votes badge"
        />
        <span class="badge-text">{badges.votes}</span>
      </div>
    {/if}

    {#if badges.location}
      <div class="badge" title="This card has a location.">
        <img
          class="badge-icon"
          src={dataImage(locationBadge)}
          height="14"
          width="14"
          alt="Location badge"
        />
      </div>
    {/if}
  {/if}

  {#if visibleFields.length > 0}
    <span class="custom-field-front-badges">
      {#each visibleFields as field}
        <div
          class="badge {field.type === 'list' ? getOption(field, getFieldItem(field)).color : ''}"
        >
          {#if field.type === 'checkbox'}
            <img
              class="badge-icon"
              src={dataImage(selectionModeBadge)}
              height="14"
              width="14"
              alt="Checkbox custom field badge"
            />
            <span class="badge-text">{field.name}</span>
          {:else}
            <span class="badge-text">
              {field.name}: {getItemValue(field)}
            </span>
          {/if}
        </div>
      {/each}
    </span>
  {/if}
</div>

<style>
  .badges {
    float: left;
    max-width: 100%;
    margin-left: -2px;
  }
  .badge,
  .badge-icon,
  .badge-text {
    vertical-align: top;
  }
  .badge {
    border-radius: 3px;
    color: #5e6c84;
    display: inline-block;
    margin: 0 4px 4px 0;
    max-width: 100%;
    min-height: 20px;
    overflow: hidden;
    position: relative;
    padding: 1px 2px;
    text-decoration: none;
    text-overflow: ellipsis;
  }
  .badge.black {
    background-color: #4d4d4d;
    color: #fff;
  }
  .badge.blue {
    background-color: #0079bf;
    color: #fff;
  }
  .badge.green {
    background-color: #61bd4f;
    color: #fff;
  }
  .badge.orange {
    background-color: #ffab4a;
    color: #fff;
  }
  .badge.red {
    background-color: #eb5a46;
    color: #fff;
  }
  .badge.yellow {
    background-color: #f2d600;
    color: #fff;
  }
  .badge.purple {
    background-color: #c377e0;
    color: #fff;
  }
  .badge.pink {
    background-color: #ff80ce;
    color: #fff;
  }
  .badge.sky {
    background-color: #00c2e0;
    color: #fff;
  }
  .badge.lime {
    background-color: #51e898;
    color: #fff;
  }
  .badge-icon {
    margin-top: 3px;
  }
  .badge-text {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
      Helvetica Neue, sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 0 4px 0 2px;
    vertical-align: top;
    white-space: nowrap;
  }
  .badge.dueSoon,
  .badge.dueNow,
  .badge.overdue,
  .badge.is-complete {
    border-radius: 3px;
    color: #fff;
  }
  .dueSoon > .badge-icon,
  .dueNow > .badge-icon,
  .overdue > .badge-icon,
  .is-complete > .badge-icon {
    filter: brightness(0) invert(1);
    margin-left: 2px;
  }
  .badge.dueSoon {
    background-color: #e6c60d;
  }
  .badge.dueNow {
    background-color: #cf513d;
  }
  .badge.overdue {
    background-color: #ec9488;
  }
  .badge.is-complete {
    background-color: #61bd4f;
  }
  .badge.is-voted {
    background-color: #e2e4e6;
    border-radius: 3px;
  }
  .badge.is-voted .badge-icon {
    margin-left: 2px;
  }
  .badge.is-template {
    background-color: #e4f0f6;
    border-radius: 3px;
    color: #172b4d;
  }
</style>
