<svelte:options tag="trello-card" />

<script>
  import './subcomponents/badges.svelte';

  import brickDiagonal from './patterns/BrickDiagonal.svg';
  import brickHorizontal from './patterns/BrickHorizontal.svg';
  import brickVertical from './patterns/BrickVertical.svg';
  import dotsChecker from './patterns/DotsChecker.svg';
  import grooveDiagonal from './patterns/GrooveDiagonal.svg';
  import scale from './patterns/Scale.svg';
  import stripeDiagonal from './patterns/StripeDiagonal.svg';
  import stripeHorizontal from './patterns/StripeHorizontal.svg';
  import stripeVertical from './patterns/StripeVertical.svg';
  import waveSquare from './patterns/WaveSquare.svg';

  // exported properties can be set from an outsider
  export let card = {
    name: '',
  };
  export let colorblind = false;
  export let labeltext = true;

  // let's also handle a case where you end up with <trello-card colorblind="true"></trello-card>
  $: showColorBlind =
    typeof colorblind === 'boolean'
      ? colorblind
      : typeof colorblind === 'string'
      ? colorblind !== 'false'
      : false;

  // let's also handle a case where you end up with <trello-card labeltext="true"></trello-card>
  $: showLabelText =
    typeof labeltext === 'boolean'
      ? labeltext
      : typeof labeltext === 'string'
      ? labeltext !== 'false'
      : true;

  const isValidTrelloUrl = (url) => {
    try {
      const parsed = new URL(url);
      if (!/^https?:$/.test(parsed.protocol)) {
        return false;
      }
      // validate that the path looks like a card
      return /^\/c\/[a-zA-Z0-9]{8,24}/.test(parsed.pathname);
    } catch (err) {
      return false;
    }
  };

  $: {
    if (!card || typeof card !== 'object') {
      throw new Error('Trello card missing or invalid card property');
    } else if (typeof card.name !== 'string') {
      throw new Error('Trello card missing name');
    } else if (card.url !== undefined && typeof card.url !== 'string') {
      throw new Error('Trello card url must be a string');
    } else if (typeof card.url === 'string' && !isValidTrelloUrl(card.url)) {
      throw new Error('Trello card url invalid');
    }
  }

  $: isSeparator = /^(?:-|_){3,}$/.test(card.name.replace(/—/g, '--'));

  const AVATAR_HOST = 'https://trello-members.s3.amazonaws.com/';

  // CARD COVER MATH
  $: cover =
    typeof card.cover === 'object'
      ? card.cover
      : (card.attachments || []).find((a) => a.id === card.idAttachmentCover);

  const EXPECTED_WIDTH = 248;
  const MAX_HEIGHT = 224;

  function closestPreview(previews, fallback) {
    const targetWidth = window.devicePixelRatio > 1 ? EXPECTED_WIDTH * 2 : EXPECTED_WIDTH;
    const sortedPreviews = (previews || [])
      .filter((preview) => preview.scaled)
      .sort((a, b) => Math.abs(b.width - targetWidth) - Math.abs(a.width - targetWidth))
      .reverse();

    return sortedPreviews.length > 0 ? sortedPreviews[0] : fallback;
  }

  $: preview = cover ? closestPreview(cover.scaled || cover.previews) : null;
  $: previewHeight =
    preview != null &&
    Math.min(
      (preview.height * EXPECTED_WIDTH) / preview.width,
      Math.min(preview.height, MAX_HEIGHT)
    );
  $: previewSize =
    preview != null &&
    (preview.height * EXPECTED_WIDTH) / preview.width <= Math.min(preview.height, MAX_HEIGHT)
      ? 'cover'
      : 'contain';

  $: isCovered = preview != null;
  $: isColored = card.cover && typeof card.cover.color === 'string';
  $: isStickered = card.stickers && card.stickers.length > 0;

  $: isFullCover = card.cover && card.cover.size === 'full' && (isColored || !!preview);
  $: useLightText = isFullCover && card.cover.brightness === 'dark';

  $: gradient = useLightText
    ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 49.07%, #000000 100%)'
    : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 52.08%, #FFFFFF 100%)';

  $: cardBackground = !isFullCover
    ? 'none'
    : isColored && showColorBlind
    ? `url('${colorBlindPattern(cover.color, cover.color !== 'black')}')`
    : !isColored
    ? `${gradient}, url('${preview.url}')`
    : 'none';

  // CARD LABEL STATE
  let labelHover = false;

  function toggleLabels(e) {
    if (e.keyCode && ![13, 32].includes(e.keyCode)) {
      // enter or space
      return;
    }
    if (e.keyCode === 32) {
      // prevent browser scroll behavior
      e.preventDefault();
    }
    labeltext = !showLabelText;
    const event = new CustomEvent('labelTextChanged', {
      detail: { labeltext },
      bubbles: true,
      cancelable: true,
      composed: true, // makes the event jump shadow DOM boundary
    });
    this.dispatchEvent(event);
  }

  function avatarForMember(member, size = 30) {
    if (typeof member.avatar === 'string' && /\/\d+\.png$/.test(member.avatar)) {
      return member.avatar.replace(/\/\d+\.png$/, `/${size}.png`);
    } else if (typeof member.avatarUrl === 'string') {
      return `${member.avatarUrl}/${size}.png`;
    } else if (typeof member.avatarHash === 'string' && member.id) {
      return `${AVATAR_HOST}${member.id}/${member.avatarHash}/${size}.png`;
    } else {
      return null;
    }
  }

  function dataImage(svg) {
    // for storybook these won't be inlined
    if (svg.endsWith('.svg')) {
      return svg;
    }
    return `data:image/svg+xml,${encodeURIComponent(svg)}`;
  }

  // helper function to switch an svg from dark to light for color blind labels
  function invertedPattern(pattern) {
    return pattern
      .replace(/fill="#fff"/g, 'fill="#000"')
      .replace(/fill-opacity="\.85"/g, 'fill-opacity=".5"');
  }

  function colorBlindPattern(color, invert = false) {
    let pattern;
    switch (color) {
      case 'blue':
        pattern = stripeHorizontal;
        break;
      case 'green':
        pattern = stripeDiagonal;
        break;
      case 'orange':
        pattern = stripeVertical;
        break;
      case 'red':
        pattern = dotsChecker;
        break;
      case 'purple':
        pattern = grooveDiagonal;
        break;
      case 'yellow':
        pattern = waveSquare;
        break;
      case 'pink':
        pattern = brickHorizontal;
        break;
      case 'sky':
        pattern = brickDiagonal;
        break;
      case 'lime':
        pattern = scale;
        break;
      case 'black':
        pattern = brickVertical;
        break;
    }
    if (pattern) {
      if (invert) {
        pattern = invertedPattern(pattern);
      }
      return dataImage(pattern);
    }
  }
</script>

<a
  class="card {isFullCover && isColored ? `cover-${cover.color}` : ''}"
  class:is-stickered={isStickered}
  class:show-label-text={showLabelText}
  class:color-blind-mode={showColorBlind}
  class:full-cover={isFullCover}
  class:is-colored={isFullCover && isColored}
  class:full-cover-dark={isFullCover && useLightText}
  class:is-covered={!isFullCover && (isCovered || isColored)}
  style="background-image: {cardBackground}; height: {isFullCover && !isColored
    ? `${previewHeight}px`
    : 'unset'};"
  href={card.url}
  target={card.url}
>
  <!-- COVER -->
  {#if isCovered && !isFullCover}
    <div
      class="card-cover"
      style="background-color: {cover.edgeColor ||
        'transparent'}; background-image: url('{preview.url}'); background-size: {previewSize}; height: {previewHeight}px;"
    />
  {:else if isColored && !isFullCover}
    <div
      class="card-cover color-card-cover cover-{cover.color}"
      style="height: {isStickered ? 64 : 32}px; background-image: {showColorBlind
        ? `url('${colorBlindPattern(cover.color, cover.color !== 'black')}')`
        : 'none'};"
    />
  {:else}
    <div class="card-cover" />
  {/if}
  <!-- STICKERS -->
  <div class="card-stickers-area">
    <div class="stickers">
      {#if isStickered}
        {#each card.stickers || [] as sticker}
          <div class="sticker" style="left:{sticker.left}%;top:{sticker.top}%;">
            <img
              class="sticker-image"
              src={sticker.imageUrl}
              style="transform: rotate({sticker.rotate}deg)"
              alt={sticker.image}
            />
          </div>
        {/each}
      {/if}
    </div>
  </div>
  <div class="card-details">
    {#if isFullCover}
      <!-- NAME -->
      <span class="card-title" dir="auto">{card.name}</span>
    {:else}
      <!-- LABELS -->
      <div
        class="card-labels"
        class:label-hover={labelHover}
        tabindex="0"
        on:focus={() => (labelHover = true)}
        on:blur={() => (labelHover = false)}
        on:keydown={toggleLabels}
      >
        {#each (card.labels || []).filter((l) => l.color != null) as label, i (label.id)}
          <!-- svelte-ignore a11y-mouse-events-have-key-events -->
          <span
            class="card-label {label.color}"
            on:click|preventDefault|stopPropagation={toggleLabels}
            on:mouseover={() => (labelHover = true)}
            on:mouseout={() => (labelHover = false)}
            title={label.name}
          >
            <span
              class="label-pattern"
              style="background-image: {showColorBlind
                ? `url('${colorBlindPattern(label.color, !label.color.startsWith('black'))}')`
                : 'none'};"
            />
            <span class="label-text">{label.name}</span>
          </span>
        {/each}
      </div>
      <!-- NAME -->
      <span class="card-title" dir="auto">
        {#if isSeparator}
          <div class="separator" />
        {:else}
          {card.name}
        {/if}
      </span>
      <!-- BADGES -->
      <trello-card-badges
        badges={card.badges || {}}
        closed={card.closed}
        customfields={card.customFields || []}
        customfielditems={card.customFieldItems || []}
        template={card.isTemplate}
      />
      <!-- MEMBERS -->
      <div class="card-members">
        {#each card.members || [] as member, i (member.id)}
          <div
            class="member"
            class:member-deactivated={member.activityBlocked || member.deactivated}
          >
            {#if avatarForMember(member)}
              <img
                class="member-avatar"
                src={avatarForMember(member)}
                srcset="{avatarForMember(member)} 1x, {avatarForMember(member, 50)} 2x"
                alt="{member.fullName} ({member.username})"
                title="{member.fullName} ({member.username})"
                width="28"
                height="28"
              />
            {:else}
              <span
                class="member-initials"
                title="@{member.username}"
                aria-label="@{member.username}"
              >
                {member.initials}
              </span>
            {/if}
          </div>
        {/each}
      </div>
    {/if}
  </div>
</a>

<style lang="less">
  @import (reference) './styles/colors.less';
  @import './styles/labelColors.less';

  :host {
    display: block;
  }
  img {
    border: 0;
  }
  .card {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
    color: #172b4d;
    cursor: pointer;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
      Helvetica Neue, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    max-width: 300px;
    min-height: 32px;
    min-width: 224px;
    position: relative;
    text-decoration: none;
    z-index: 0;
  }

  .card-cover {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px 3px 0 0;
    min-height: 1px;
    user-select: none;
  }
  .card.full-cover {
    background-size: cover;
    display: flex;
    flex-direction: row;
    min-height: 56px;
  }
  .card.full-cover.is-colored.color-blind-mode {
    background-size: 16px 16px;
    background-repeat: repeat-y;
    background-position: 0;
  }

  .card-stickers-area {
    border-radius: 3px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;
  }
  .card-stickers-area .stickers {
    height: 155px;
    position: relative;
    width: 100%;
  }
  .card.is-stickered .stickers {
    height: 64px;
  }
  .card.is-stickered .card-details {
    background-color: hsla(0, 0%, 100%, 0.7);
    border-radius: 0 0 3px 3px;
    margin-top: 55px;
  }
  .card.is-stickered.is-covered .card-details {
    margin-top: 0;
  }
  .card.full-cover.is-stickered .card-details {
    background-color: unset;
    margin-top: 0;
  }
  .card.full-cover .card-details {
    align-self: flex-end;
  }
  .card.full-cover.color-blind-mode .card-details {
    left: 16px;
  }
  .sticker {
    display: block;
    height: 64px;
    left: 0;
    position: absolute;
    top: 0;
    width: 64px;
    z-index: 0;
    transition: opacity 85ms ease-in, -webkit-transform 85ms ease-in;
    transition: transform 85ms ease-in, opacity 85ms ease-in;
    transition: transform 85ms ease-in, opacity 85ms ease-in, -webkit-transform 85ms ease-in;
    user-select: none;
  }
  .sticker-image {
    height: 4pc;
    width: 4pc;
    color: transparent;
  }

  .card-details {
    overflow: hidden;
    padding: 6px 8px 2px;
    position: relative;
    z-index: 10;
  }
  .card-labels:empty {
    display: none;
    margin: 0;
  }
  .card-labels {
    overflow: auto;
    position: relative;
  }
  .card-labels:focus-visible {
    outline: none;
  }
  .card-label {
    background-color: var(--foreground-color);
    border-radius: 4px;
    box-sizing: border-box;
    color: transparent;
    display: block;
    float: left;
    font-size: 12px;
    font-weight: 400;
    height: 8px;
    line-height: 8px;
    margin: 0 4px 4px 0;
    max-width: 40px;
    min-width: 40px;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-overflow: ellipsis;
    text-shadow: none;
    white-space: nowrap;
    width: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .show-label-text & {
      background-color: var(--background-color);
      color: @N800;
      height: 16px;
      line-height: 16px;
      max-width: 100%;
      min-width: 56px;
      padding-left: 16px;
      padding-right: 8px;
    }

    .color-blind-mode.show-label-text & {
      padding-left: 24px;
    }

    .label-hover & {
      background-color: var(--hover-foreground-color);
    }

    .show-label-text .label-hover & {
      background-color: var(--hover-background-color);
    }
  }

  .label-pattern {
    display: none;
    position: absolute;

    .show-label-text & {
      display: inline;
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 4px;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: var(--foreground-color);
    }

    .color-blind-mode & {
      display: inline;
      inset: 0;
    }

    .color-blind-mode.show-label-text & {
      border-radius: 4px;
      width: 16px;
      height: 16px;
    }
  }

  .cover-blue {
    background-color: #5ba4cf;
  }
  .cover-green {
    background-color: #7bc86c;
  }
  .cover-orange {
    background-color: #ffaf3f;
  }
  .cover-purple {
    background-color: #cd8de5;
  }
  .cover-red {
    background-color: #ef7564;
  }
  .cover-yellow {
    background-color: #f5dd29;
  }
  .cover-sky {
    background-color: #29cce5;
  }
  .cover-lime {
    background-color: #6deca9;
  }
  .cover-pink {
    background-color: #ff8ed4;
  }
  .cover-black {
    background-color: #172b4d;
  }

  .color-blind-mode .color-card-cover {
    background-position: left;
    background-repeat: repeat-y;
    background-size: 16px 16px;
  }

  .card-title {
    clear: both;
    color: #172b4d;
    display: block;
    margin: 0 0 4px;
    overflow: hidden;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
  }
  .full-cover .card-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 500;
  }
  .full-cover-dark .card-title {
    color: #fff;
  }
  .separator {
    align-items: center;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 20px;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 70%;
  }
  .separator:before {
    border-top: 2px solid #c1c7d0;
    content: '';
    display: block;
    width: 100%;
  }
  .card-members {
    float: right;
    margin: 0 -2px 0 0;
  }
  .member {
    background-color: #dfe1e6;
    border-radius: 14px;
    color: #172b4d;
    display: block;
    float: right;
    height: 28px;
    margin: 0 0 4px 4px;
    overflow: visible;
    position: relative;
    text-decoration: none;
    user-select: none;
    width: 28px;
    z-index: 0;
  }
  .member.member-deactivated {
    background-color: #f4f5f7;
  }
  .member.member-deactivated .member-avatar {
    opacity: 0.2;
  }
  .member.member-deactivated .member-initials {
    background-color: transparent;
    color: #5e6c84;
  }
  .member-avatar {
    border-radius: 14px;
    object-fit: cover;
  }
  .member-initials {
    background-color: #dfe1e6;
    border-radius: 14px;
    display: block;
    font-size: 12px;
    font-weight: 700;
    height: 28px;
    left: 0;
    line-height: 28px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    width: 28px;
  }
</style>
